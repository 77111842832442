import _ from "lodash";
import React from "react";

import { FaLocationArrow, FaStar, FaChevronRight } from "react-icons/fa";

import Resto1 from "../../assets/images/resto/resto1.png";
import Resto2 from "../../assets/images/resto/resto2.png";
import UchinoBanner from "../../assets/images/resto/uchino.jpg";
import OldTownBanner from "../../assets/images/resto/oldtown.jpg";

import "./ContentPopular.scss";

export const ContentPopular = () => {
  return (
    <div id="popular" className="content-popular">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="header-popular">
              <h2 className="title">Restoran Populer</h2>
              <h5 className="subtitle">Restoran Favorit Rekomendasi Kami</h5>
            </div>
          </div>
          <div className="col-lg-3 button-popular">
            <a href='/product' className='btn btn-base btn-danger'>Lihat Semua</a>
          </div>
          <div className="col-lg-6">
            <div className="card card-popular">
              <div
                className="card-header"
                style={{
                  backgroundImage: `url(${UchinoBanner})`
                }}
              >
                <div className="card-popular-title">
                  <img src={Resto1} alt="Resto Uchino" />
                  <div className="card-popular-text">
                    <div className="title">Uchino Shokudo - ÆON Mall BSD City</div>
                    <div className="price">Rp 90.000 - 500.000</div>
                  </div>
                </div>
              </div>
              <div className="card-body card-popular-detail">
                <h5>
                  <span className="badge text-bg-danger">
                    <FaLocationArrow /> 4.5 km
                  </span>
                  <span className="badge text-bg-danger">
                    <FaStar /> 4.9 <span>(10k+)</span>
                  </span>
                </h5>
                <ul className="list-group list-group-flush">
                  { _.map([
                    { name: "Grilled Mackarel Set", price: "165.550" },
                    { name: "Chicken Katsu Curry Rice", price: "98.148" },
                    { name: "Grilled Salmon Set", price: "186.835" }
                  ], obj => (
                    <li className="list-group-item">
                      <img src={Resto1} alt="Resto Uchino" className="item-popular" />
                      <div className="item-popular-text">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-1">{obj.name}</h5>
                          <span>Rp {obj.price}<FaChevronRight /></span>
                        </div>
                        <small>Fried Makarel + Rice + Tofu + Eggplant + Jelly</small>
                      </div>
                    </li>
                  )) }
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card card-popular">
              <div
                className="card-header"
                style={{
                  backgroundImage: `url(${OldTownBanner})`
                }}
              >
                <div className="card-popular-title">
                  <img src={Resto2} alt="Resto Oldtown" />
                  <div className="card-popular-text">
                    <div className="title">OldTown White Coffee</div>
                    <div className="price">Rp 40.000 - 150.000</div>
                  </div>
                </div>
              </div>
              <div className="card-body card-popular-detail">
                <h5>
                  <span className="badge text-bg-danger">
                    <FaLocationArrow /> 3.5 km
                  </span>
                  <span className="badge text-bg-danger">
                    <FaStar /> 4.9 <span>(10k+)</span>
                  </span>
                </h5>
                <ul className="list-group list-group-flush">
                  { _.map([
                    { name: "Oldtown White Coffee", price: "50.820" },
                    { name: "Butter Toast", price: "44.770" },
                    { name: "Roat Beer Float", price: "47.190" }
                  ], obj => (
                    <li className="list-group-item">
                      <img src={Resto2} alt="Resto Oldtown" className="item-popular" />
                      <div className="item-popular-text">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-1">{obj.name}</h5>
                          <span>Rp {obj.price}<FaChevronRight /></span>
                        </div>
                        <small>Coffee + Toast</small>
                      </div>
                    </li>
                  )) }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}