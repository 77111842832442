import React from "react";

import { FaMapMarkerAlt, FaStar } from "react-icons/fa";

import UchinoBanner from "../../assets/images/resto/uchino.jpg";
import OldTownBanner from "../../assets/images/resto/oldtown.jpg";

import "./ContentProduct.scss";

export const ContentProduct = () => {
  return (
    <div id="product" className="content-product">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="header-popular">
              <h2 className="title">Restoran Populer</h2>
              <h5 className="subtitle">Restoran Favorit Rekomendasi Kami</h5>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card card-product">
              <div
                className="card-header"
                style={{
                  backgroundImage: `url(${UchinoBanner})`
                }}
              />
              <div className="card-body card-product-detail">
                <div className="card-product-text">
                  <div className="title">Uchino Shokudo - ÆON Mall BSD City</div>
                  <div className="price">
                    <span>
                      <FaStar /> 4.9
                    </span> 
                    Rp 90.000 - 500.000
                  </div>
                </div>
                <h5 className="card-product-category-block">
                  <span className="badge card-product-category">
                    Japanese
                  </span>
                  <span className="badge card-product-category">
                    Seafood
                  </span>
                </h5>
                <div>
                  <span className="marker">
                    <FaMapMarkerAlt />
                  </span>
                  ÆON Mall BSD City - Lantai Ground
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card card-product">
              <div
                className="card-header"
                style={{
                  backgroundImage: `url(${OldTownBanner})`
                }}
              />
              <div className="card-body card-product-detail">
                <div className="card-product-text">
                  <div className="title">OldTown White Coffee</div>
                  <div className="price">
                    <span>
                      <FaStar /> 4.9
                    </span> 
                    Rp 40.000 - 150.000
                  </div>
                </div>
                <h5 className="card-product-category-block">
                  <span className="badge card-product-category">
                    Coffee
                  </span>
                  <span className="badge card-product-category">
                    Toast
                  </span>
                </h5>
                <div>
                  <span className="marker">
                    <FaMapMarkerAlt />
                  </span>
                  ÆON Mall BSD City - Lantai Basement
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}