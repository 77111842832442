import React from 'react';
import { Outlet } from 'react-router-dom';

import DineFindLogo from '../assets/images/dinefind-logo.svg';
import DineFindWhiteLogo from '../assets/images/white-logo.svg';

export const MainLayout = () => {
  return (
    <React.Fragment>
      <nav className="navbar fixed-top navbar-expand-lg app-header">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={DineFindLogo} alt='DineFind Logo' height={42} className='d-inline-block align-text-top' />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarController" aria-controls="navbarController" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarController">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a href="#homepage" className='nav-link app-button'>Beranda</a>
              </li>
              <li className="nav-item">
                <a href="#service" className='nav-link app-button'>Layanan</a>
              </li>
              <li className="nav-item">
                <a href="#guideline" className='nav-link app-button'>Cara Kerja</a>
              </li>
              <li className="nav-item">
                <a href="#product" className='nav-link app-button'>Populer</a>
              </li>
              <li className="nav-item">
                <a href="#download" className='nav-link app-button'>Download</a>
              </li>
            </ul>
            <div className="d-flex">
              <a href='/' className='btn btn-base btn-danger'>Jadi Mitra Kami</a>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
      <div className='footer-block'  >
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <img src={DineFindWhiteLogo} alt='DineFind White Logo' />
              <p>
                <strong>DineFind - Roseville SOHO and Suite</strong><br />
                Sunburst CBD, Jl. Kapten Soebijanto Djojohadikusumo No.8 Lot I, Lengkong Gudang, South Tangerang City, Banten
              </p>
            </div>
            <div className='col-md-7'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='footer-menu-title'>Perusahaan</div>
                  <div className='footer-menu-list'>Tentang Kami</div>
                  <div className='footer-menu-list'>Produk</div>
                  <div className='footer-menu-list'>Lokasi Kami</div>
                </div>
                <div className='col-md-4'>
                  <div className='footer-menu-title'>Kemitraan</div>
                  <div className='footer-menu-list'>Mitra Usaha</div>
                </div>
                <div className='col-md-4'>
                  <div className='footer-menu-title'>Lainnya</div>
                  <div className='footer-menu-list'>Syarat dan Ketentuan</div>
                  <div className='footer-menu-list'>Kebijakan Privasi</div>
                  <div className='footer-menu-list'>Bantuan</div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='copyright'>All Rights Reserved | ©️ 2024 DineFind.</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}