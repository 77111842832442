import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/stylesheets/index.scss"

import { MainLayout } from "./layouts/MainLayout";
import { DetailLayout } from "./layouts/DetailLayout";

import { HomePage } from "./pages/HomePage";
import { ProductPage } from "./pages/ProductPage";

const elementRoot = document.getElementById("root");
const createRoot = ReactDOM.createRoot(elementRoot);

createRoot.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="/product" element={<DetailLayout />}>
          <Route index element={<ProductPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
