import React from "react";

import Category1 from '../../assets/images/category/category1.png';
import Category2 from '../../assets/images/category/category2.png';
import Category3 from '../../assets/images/category/category3.png';
import Category4 from '../../assets/images/category/category4.png';
import Category5 from '../../assets/images/category/category5.png';
import Category6 from '../../assets/images/category/category6.png';

import "./ContentCategory.scss";

export const ContentCategory = () => {
  return (
    <div className="container content-category">
      <div className="row">
        <div className="col-md-12">
          <div className="title">Katagori Makanan Populer</div>
          <div className="subtitle">Temukan hidangan istimewa lainnya</div>
        </div>
        <div className="col-md-4 card-category">
          <img src={Category1} alt='Japanese' />
          <div className='card-text'>Nikmati hidangan Jepang seperti sushi, sashimi, dan donburi bowls yang pasti akan memuaskan selera kamu!</div>
        </div>
        <div className="col-md-4 card-category">
          <img src={Category2} alt='Korean' />
          <div className='card-text'>Rasakan cita rasa klasik Korea dengan berbagai hidangan yang siap memanjakan lidah kamu!</div>
        </div>
        <div className="col-md-4 card-category">
          <img src={Category3} alt='Indonesian' />
          <div className='card-text'>Nikmati makanan khas Indonesia dengan cita rasa yang otentik dan rempah-rempah yang kaya!</div>
        </div>
        <div className="col-md-4 card-category">
          <img src={Category4} alt='Chinese' />
          <div className='card-text'>Temukan hidangan favorit keluarga dengan makanan khas China yang memberikan kenyamanan dan kenikmatan!</div>
        </div>
        <div className="col-md-4 card-category">
          <img src={Category5} alt='Coffee' />
          <div className='card-text'>Jika kamu mencari dorongan energi cepat, pilihan kopi kami adalah solusinya!</div>
        </div>
        <div className="col-md-4 card-category">
          <img src={Category6} alt='Desserts' />
          <div className='card-text'>Bagi pecinta manis, berbagai pilihan hidangan penutup kami siap memuaskan hasrat kamu!</div>
        </div>
      </div>
    </div>
  );
}