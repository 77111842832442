import React from "react";

import Delivery from '../../assets/images/delivery.png';
import DineIn from '../../assets/images/dinein.png';
import Takeaway from '../../assets/images/takeaway.png';
import Reserve from '../../assets/images/reserve.png';

import "./ContentService.scss";

export const ContentService = () => {
  return (
    <div id="service" className="content-service">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header-service">
              <h5 className="indicator">Layanan</h5>
              <h1 className="title">Layanan Utama DineFind</h1>
              <h5 className="subtitle">Nikmati Kemudahan dalam memesan dan reservasi restoran favorit kamu!</h5>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-service">
              <img src={Delivery} alt='Delivery Service' className="card-image" />
              <div className="card-text">
                <div className="title">Anter (Delivery)</div>
                <div className="content">
                  Nikmati kemudahan memesan makanan favorit kamu dan mengantarkannya langsung ke lokasi kamu. Dengan layanan Antar DineFind, makanan kamu tiba dengan cepat dan aman.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-service">
              <img src={DineIn} alt='Delivery Service' className="card-image" />
              <div className="card-text">
                <div className="title">Makan di Tempat (Dine-in)</div>
                <div className="content">
                  Reservasi meja di restoran favorit kamu dengan mudah melalui aplikasi DineFind. Hindari antrian panjang dan pastikan kamu mendapatkan tempat terbaik untuk menikmati makanan.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-service">
              <img src={Takeaway} alt='Delivery Service' className="card-image" />
              <div className="card-text">
                <div className="title">Bawa Pulang (Takeaway)</div>
                <div className="content">
                  Pesan makanan untuk dibawa pulang tanpa perlu menunggu lama. Layanan Bawa Pulang DineFind memungkinkan kamu mengambil pesanan dengan cepat dan praktis.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-service reverse">
              <img src={Reserve} alt='Delivery Service' className="card-image" />
              <div className="card-text">
                <div className="title">Reservasi (Reserve)</div>
                <div className="content">
                  Lakukan reservasi tempat di restoran pilihan kamu untuk acara spesial atau makan bersama keluarga. Pastikan tempat kamu dengan layanan Reservasi dari DineFind.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}