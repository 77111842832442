import React from "react";

import BackgroundBanner from "../../assets/images/bg-handwriter.png";
import PhoneBottom from "../../assets/images/phone-bottom.png";
import GooglePlay from "../../assets/images/googleplay.png";
import AppStore from "../../assets/images/appstore.png";

import "./ContentInformation.scss";

export const ContentInformation = () => {
  return (
    <div id="download" className="information-block" style={{ backgroundImage: `url(${BackgroundBanner})` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 card-information">
            <table width={"75%"} height={"100%"}>
              <tr>
                <td>
                  <div className="title">Unduh Aplikasi DineFind</div>
                  <div className="subtitle">Nikmati Kemudahan Memesan Makanan Langsung dari Genggaman Kamu!</div>
                  <div className="download">
                    <a href="/">
                      <img src={GooglePlay} alt="Download via Google Play" />
                    </a>
                    <a href="/">
                      <img src={AppStore} alt="Download via App Store" />
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div className="col-md-6 card-information">
            <img src={PhoneBottom} alt="Phone Bottom" className="phone-image" />
          </div>
        </div>
      </div>
    </div>
  );
}