import React from 'react';

import { ContentProduct } from '../components/ContentProduct/ContentProduct';
import { ContentCategory } from '../components/ContentCategory/ContentCategory';
import { ContentInformation } from '../components/ContentInformation/ContentInformation';

export const ProductPage = () => {
  return (
    <>
      <ContentProduct />
      <ContentCategory />
      <ContentInformation />
    </>
  );
}