import React from "react";

import DinefindMobile from '../../assets/images/dinefind-mobile.png';
import BackgroundBanner from '../../assets/images/bg-handwriter.png';

import "./ContentGuideline.scss";

export const ContentGuideline = () => {
  return (
    <div
      id="guideline"
      className="content-guideline"
      style={{
        backgroundImage: `url(${BackgroundBanner})`
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={DinefindMobile} alt='Mobile DineFind' className="image-guideline" />
          </div>
          <div className="col-md-6">
            <table className="table-guideline">
              <tbody>
                <tr>
                  <td>
                    <div className="row">
                      <div className="col-12">
                        <h2 className="title-guideline">Yuk, Mulai dengan DineFind</h2>
                        <h5 className="detail-guideline">Pesan Makanan Favori Kamu dengan Mudah</h5>
                      </div>
                      <div className="col-12">
                        <div className="number-guideline">1</div>
                        <div className="text-guideline">
                          <div className="title">Unduh</div>
                          <div className="detail">Unduh aplikasi DineFind dari AppStore atau GooglePlay. Aplikasi ini tersedia untuk perangkat IOS dan Android.</div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="number-guideline">2</div>
                        <div className="text-guideline">
                          <div className="title">Pilih Restoran</div>
                          <div className="detail">Buka aplikasi dan pilih restoran favorit kamu dari daftar yang tersedia. Jelajahi menu dan pilih makanan yang kamu inginkan.</div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="number-guideline">3</div>
                        <div className="text-guideline">
                          <div className="title">Pesan Makanan</div>
                          <div className="detail">Pesan makanan yang kami inginkan, pilih opsi pengantaran, makan di tempat, bawa pulang, atau reservasi. Lengkapi detail pesanan dan konfirmasi.</div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="number-guideline">4</div>
                        <div className="text-guideline">
                          <div className="title">Nikmati Makanan</div>
                          <div className="detail">Tunggu pesanan kamu tiba di lokasi, ambil pesanan di restoran, atau nikmati makan di tempat. Selamat menikmati makanan favorit kamu.</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}