import React from "react";

import GooglePlay from "../../assets/images/googleplay.png";
import AppStore from "../../assets/images/appstore.png";
import JumbotronBanner from "../../assets/images/cafe-image.png";
import BackgroundBanner from "../../assets/images/bg-handwriter.png";

import "./HeaderBanner.scss";

export const HeaderBanner = () => {
  const contentBanner = (
    <>
      <h2 className="title">Temukan Makanan Favoritmu dengan Mudah</h2>
      <h5 className="subtitle">
        <strong>DineFind</strong> memudahkan dalam memasan makanan, menikmati makan di tempat, 
        atau melakukan reservasi di restoran pilihanmu. Unduh aplikasi kami sekarang!
      </h5>
      <div className="download">
        <a href="/"><img src={GooglePlay} alt="Download via Google Play" /></a>
        <a href="/"><img src={AppStore} alt="Download via App Store" /></a>
      </div>
    </>
  )

  return (
    <div
      id="homepage"
      className="header-banner"
      style={{
        backgroundImage: `url(${BackgroundBanner})`
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <table className="table-banner">
              <tbody>
                <tr className="on-desktop">
                  <td className="left-banner">
                    {contentBanner}
                  </td>
                  <td className="right-banner">
                    <img src={JumbotronBanner} alt="Food Table" />
                  </td>
                </tr>
                <tr className="on-mobile">
                  <td
                    className="mobile-banner"
                    colSpan={2}
                  >
                    <div className="content-box">
                      {contentBanner}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}