import React from 'react';

import { HeaderBanner } from '../components/HeaderBanner/HeaderBanner';
import { ContentService } from '../components/ContentService/ContentService';
import { ContentGuideline } from '../components/ContentGuideline/ContentGuideline';
import { ContentPopular } from '../components/ContentPopular/ContentPopular';
import { ContentCategory } from '../components/ContentCategory/ContentCategory';
import { ContentInformation } from '../components/ContentInformation/ContentInformation';

export const HomePage = () => {
  return (
    <>
      <HeaderBanner />
      <ContentService />
      <ContentGuideline />
      <ContentPopular />
      <ContentCategory />
      <ContentInformation />
    </>
  );
}