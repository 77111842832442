import React from 'react';
import { Outlet } from 'react-router-dom';

import { FaChevronRight } from "react-icons/fa";

import DineFindLogo from '../assets/images/dinefind-logo.svg';
import DineFindWhiteLogo from '../assets/images/white-logo.svg';

export const DetailLayout = () => {
  return (
    <React.Fragment>
      <nav className="navbar fixed-top navbar-expand-lg app-header app-header-line">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={DineFindLogo} alt='DineFind Logo' height={42} className='d-inline-block align-text-top' />
          </a>
          <div className="d-flex">
            <a href='/' className='btn btn-link btn-back'>Kembali <FaChevronRight /></a>
          </div>
        </div>
      </nav>
      <Outlet />
      <div className='footer-block'  >
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <img src={DineFindWhiteLogo} alt='DineFind White Logo' />
              <p>
                <strong>DineFind - Roseville SOHO and Suite</strong><br />
                Sunburst CBD, Jl. Kapten Soebijanto Djojohadikusumo No.8 Lot I, Lengkong Gudang, South Tangerang City, Banten
              </p>
            </div>
            <div className='col-md-7'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='footer-menu-title'>Perusahaan</div>
                  <div className='footer-menu-list'>Tentang Kami</div>
                  <div className='footer-menu-list'>Produk</div>
                  <div className='footer-menu-list'>Lokasi Kami</div>
                </div>
                <div className='col-md-4'>
                  <div className='footer-menu-title'>Kemitraan</div>
                  <div className='footer-menu-list'>Mitra Usaha</div>
                </div>
                <div className='col-md-4'>
                  <div className='footer-menu-title'>Lainnya</div>
                  <div className='footer-menu-list'>Syarat dan Ketentuan</div>
                  <div className='footer-menu-list'>Kebijakan Privasi</div>
                  <div className='footer-menu-list'>Bantuan</div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='copyright'>All Rights Reserved | ©️ 2024 DineFind.</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}